<template>
  <div class="single blog page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">Spanish for Travellers - Asking in a Restaurant</h2>
    </section>
    <div class="container 75%">
      <section class="row 200%">
        <div>
          <div class="row">
            <div class="col-lg-6">
              <p>Hola {{ getCurrentSeasonHeart() }}</p>
              <p>¿Do you like to travel? ✈️ ¿Which is the main inconvenience you face when you travel to a foreign country?<p>
              <p>Let me guess…</p>
              <p><b>The language! Be able to communicate with the locals!</b></p>
            </div>
            <div class="col-lg-6">
              <img
                src="/images/blog/spanish-for-travellers/restaurant/main.jpg"
                alt="Starting the duolingo app in a smartphone"
                loading="lazy"/>
            </div>
          </div>
          <p>If the answer is yes, you are at the right place - most of my students love to eat in local places and interact with locals, that’s why they decided to start learning Spanish.</p>
          <p>If you would like to order your favourite food in a restaurant and enjoy more your trips learning about culture and with locals (the same that my students do) keep reading... 👇</p>
          <h3>Here you can learn about ordering in a restaurant:</h3>
          <section class="topic">
            <header>
              <h4 class="title">1) Order dishes 🥘</h4>
              <span class="translation">Pedir platos</span>
            </header>
            <div class="question">
              <p><b>a)</b> Could you bring me the menu, please?</p>
              <p>¿Puede traerme la carta/menú por favor?</p>
            </div>
            <div class="question">
              <p><b>b)</b> Could you take notes of my drinks?</p>
              <p>¿Me toma nota de la bebida?</p>
            </div>
            <div class="question">
              <p><b>c)</b> Do you have soup of the day?</p>
              <p>¿Tiene sopa del día?</p>
            </div>
            <div class="question">
              <p><b>d)</b> Complete order</p>
              <div>
                <p><i>De entrantes quiero...</i> (starter)</p>
                <p><i>De primero quiero...</i> (first course)</p>
                <p><i>De segundo quiero...</i> (second course)</p>
                <p><i>De postre quiero...</i> (dessert)</p>
                <p><i>Voy a beber agua/zumo/vino/un refresco/cerveza</i> (drinks)</p>
              </div>
            </div>
          </section>
          <section class="topic">
            <header>
              <h4 class="title">2) Ask about a dish ❓</h4>
              <span class="translation">Preguntar sobre un plato</span>
            </header>
            <div class="question">
              <p><b>a)</b> What is <i>tortilla de patatas</i>/<i>salmorejo</i>?</p>
              <p>¿Qué es la tortilla de patatas/el salmorejo?</p>
            </div>
            <div class="question">
              <p><b>b)</b> Which ingredients has <i>gazpacho</i>/<i>paella</i>?</p>
              <p>¿Qué ingredientes lleva el gazpacho/la paella?</p>
            </div>
            <div class="question">
              <p><b>c)</b> How is <i>pescado</i>/<i>cocido</i> prepared?</p>
              <p>¿Cómo esta hecho el pescado/ el cocido?</p>
            </div>
            <div class="question">
              <p><b>d)</b> Is the apple pie homemade?</p>
              <p>¿Es la tarta de manzana casera?</p>
            </div>
          </section>
          <section class="topic">
            <header>
              <h4 class="title">3) Ask for something missing</h4>
              <span class="translation">Pedir algo que falta</span>
            </header>
            <div class="question">
              <p><b>a)</b> Could you bring me a spoon/fork/knife?</p>
              <p>¿Me puede traer una cuchara/un tenedor/un cuchillo?</p>
            </div>
            <div class="question">
              <p><b>b)</b> Sorry, could you bring me more bread?</p>
              <p>Perdone, ¿Puede traerme más pan?</p>
            </div>
            <div class="question">
              <p><b>c)</b> Excuse me, could you bring me another napkin?</p>
              <p>Disculpe, ¿Puede traerme otra servilleta?</p>
            </div>
          </section>
          <section class="topic">
            <header>
              <h4 class="title">4) Express like 👍 or dislike 👎</h4>
              <span class="translation">Expresar gusto/disgusto</span>
            </header>
            <div class="question">
              <p><b>a)</b> The seafood was yummy/delicious/bad/salty/bland</p>
              <p>El marisco estaba riquísimo/delicioso/malísimo/salado/soso</p>
            </div>
            <div class="question">
              <p><b>b)</b> Everything was very good</p>
              <p>Todo estaba muy bueno</p>
            </div>
          </section>
          <section class="topic">
            <header>
              <h4 class="title">5) Preferences or allergies</h4>
              <span class="translation">Preferencias y/o alergias</span>
            </header>
            <div class="question">
              <p><b>a)</b> Could you remove the tomato from the hamburger?</p>
              <p>¿Me podría quitar el tomate de la hamburguesa?</p>
            </div>
            <div class="question">
              <p><b>b)</b> I like veal well done/rarely done/done</p>
              <p>Me gusta la ternera bien hecha/poco hecha/en su punto</p>
            </div>
            <div class="question">
              <p><b>b)</b> Do you have any vegetarian tapas?</p>
              <p>¿Tiene algunas tapas vegetarianas?</p>
            </div>
            <div class="question">
              <p><b>b)</b> I am allergic to lactose</p>
              <p>Soy alérgica a la lactosa</p>
            </div>
          </section>
          <section class="topic">
            <header>
              <h4 class="title">6) Ask for the bill 🧾💳</h4>
              <span class="translation">Pedir la cuenta</span>
            </header>
            <div class="question">
              <p><b>a)</b> Could you bring me the bill, please?</p>
              <p>¿Podría traerme la cuenta, por favor?</p>
            </div>
            <div class="question">
              <p><b>b)</b> How much is it/does it cost?</p>
              <p>¿Cuánto es/cuesta?</p>
            </div>
            <div class="question">
              <p><b>b)</b> Could I pay by card/cash?</p>
              <p>¿Podría pagar con tarjeta/en efectivo?</p>
            </div>
          </section>
          <p>Well, would you like to practise all what you learn today?</p>
          <ul>
            <li><a href="/files/blog/spanish-for-travellers/restaurant.pdf" target="_blank">Exercise to practise on how to ask in a restaurant</a></li>
          </ul>
          <p>See you soon until then, BUEN PROVECHO 😊 {{ getCurrentSeasonEmoji() }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'FindThePerfectTutor',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  img {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 2em;
  }

  .blog.single > .container {
    margin-top: 50px;
  }

  blockquote {
    margin-bottom: 2em;
  }

  blockquote p {
    margin: 0;
  }

  ul {
    list-style: none;
  }

  .topic header .title {
    margin: 0;
  }

  .topic header .translation {
    font-style: italic;
  }

  .topic .question {
    margin: 2em 0 2em 0;
  }

  .topic .question > p:last-child {
    font-style: italic;
  }

  .topic .question p {
    margin: 0;
  }

</style>
