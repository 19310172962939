var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"single blog page"},[_c('section',{staticClass:"align-middle",attrs:{"id":"banner"}},[_c('banner-image-overlay'),_c('h2',{staticClass:"title"},[_vm._v("Spanish for Travellers - Asking in a Restaurant")])],1),_c('div',{staticClass:"container 75%"},[_c('section',{staticClass:"row 200%"},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('p',[_vm._v("Hola "+_vm._s(_vm.getCurrentSeasonHeart()))]),_c('p',[_vm._v("¿Do you like to travel? ✈️ ¿Which is the main inconvenience you face when you travel to a foreign country?")]),_c('p'),_c('p',[_vm._v("Let me guess…")]),_vm._m(0)]),_vm._m(1)]),_c('p',[_vm._v("If the answer is yes, you are at the right place - most of my students love to eat in local places and interact with locals, that’s why they decided to start learning Spanish.")]),_c('p',[_vm._v("If you would like to order your favourite food in a restaurant and enjoy more your trips learning about culture and with locals (the same that my students do) keep reading... 👇")]),_c('h3',[_vm._v("Here you can learn about ordering in a restaurant:")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('p',[_vm._v("Well, would you like to practise all what you learn today?")]),_vm._m(8),_c('p',[_vm._v("See you soon until then, BUEN PROVECHO 😊 "+_vm._s(_vm.getCurrentSeasonEmoji()))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("The language! Be able to communicate with the locals!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6"},[_c('img',{attrs:{"src":"/images/blog/spanish-for-travellers/restaurant/main.jpg","alt":"Starting the duolingo app in a smartphone","loading":"lazy"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"topic"},[_c('header',[_c('h4',{staticClass:"title"},[_vm._v("1) Order dishes 🥘")]),_c('span',{staticClass:"translation"},[_vm._v("Pedir platos")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("a)")]),_vm._v(" Could you bring me the menu, please?")]),_c('p',[_vm._v("¿Puede traerme la carta/menú por favor?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" Could you take notes of my drinks?")]),_c('p',[_vm._v("¿Me toma nota de la bebida?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("c)")]),_vm._v(" Do you have soup of the day?")]),_c('p',[_vm._v("¿Tiene sopa del día?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("d)")]),_vm._v(" Complete order")]),_c('div',[_c('p',[_c('i',[_vm._v("De entrantes quiero...")]),_vm._v(" (starter)")]),_c('p',[_c('i',[_vm._v("De primero quiero...")]),_vm._v(" (first course)")]),_c('p',[_c('i',[_vm._v("De segundo quiero...")]),_vm._v(" (second course)")]),_c('p',[_c('i',[_vm._v("De postre quiero...")]),_vm._v(" (dessert)")]),_c('p',[_c('i',[_vm._v("Voy a beber agua/zumo/vino/un refresco/cerveza")]),_vm._v(" (drinks)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"topic"},[_c('header',[_c('h4',{staticClass:"title"},[_vm._v("2) Ask about a dish ❓")]),_c('span',{staticClass:"translation"},[_vm._v("Preguntar sobre un plato")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("a)")]),_vm._v(" What is "),_c('i',[_vm._v("tortilla de patatas")]),_vm._v("/"),_c('i',[_vm._v("salmorejo")]),_vm._v("?")]),_c('p',[_vm._v("¿Qué es la tortilla de patatas/el salmorejo?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" Which ingredients has "),_c('i',[_vm._v("gazpacho")]),_vm._v("/"),_c('i',[_vm._v("paella")]),_vm._v("?")]),_c('p',[_vm._v("¿Qué ingredientes lleva el gazpacho/la paella?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("c)")]),_vm._v(" How is "),_c('i',[_vm._v("pescado")]),_vm._v("/"),_c('i',[_vm._v("cocido")]),_vm._v(" prepared?")]),_c('p',[_vm._v("¿Cómo esta hecho el pescado/ el cocido?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("d)")]),_vm._v(" Is the apple pie homemade?")]),_c('p',[_vm._v("¿Es la tarta de manzana casera?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"topic"},[_c('header',[_c('h4',{staticClass:"title"},[_vm._v("3) Ask for something missing")]),_c('span',{staticClass:"translation"},[_vm._v("Pedir algo que falta")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("a)")]),_vm._v(" Could you bring me a spoon/fork/knife?")]),_c('p',[_vm._v("¿Me puede traer una cuchara/un tenedor/un cuchillo?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" Sorry, could you bring me more bread?")]),_c('p',[_vm._v("Perdone, ¿Puede traerme más pan?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("c)")]),_vm._v(" Excuse me, could you bring me another napkin?")]),_c('p',[_vm._v("Disculpe, ¿Puede traerme otra servilleta?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"topic"},[_c('header',[_c('h4',{staticClass:"title"},[_vm._v("4) Express like 👍 or dislike 👎")]),_c('span',{staticClass:"translation"},[_vm._v("Expresar gusto/disgusto")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("a)")]),_vm._v(" The seafood was yummy/delicious/bad/salty/bland")]),_c('p',[_vm._v("El marisco estaba riquísimo/delicioso/malísimo/salado/soso")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" Everything was very good")]),_c('p',[_vm._v("Todo estaba muy bueno")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"topic"},[_c('header',[_c('h4',{staticClass:"title"},[_vm._v("5) Preferences or allergies")]),_c('span',{staticClass:"translation"},[_vm._v("Preferencias y/o alergias")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("a)")]),_vm._v(" Could you remove the tomato from the hamburger?")]),_c('p',[_vm._v("¿Me podría quitar el tomate de la hamburguesa?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" I like veal well done/rarely done/done")]),_c('p',[_vm._v("Me gusta la ternera bien hecha/poco hecha/en su punto")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" Do you have any vegetarian tapas?")]),_c('p',[_vm._v("¿Tiene algunas tapas vegetarianas?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" I am allergic to lactose")]),_c('p',[_vm._v("Soy alérgica a la lactosa")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"topic"},[_c('header',[_c('h4',{staticClass:"title"},[_vm._v("6) Ask for the bill 🧾💳")]),_c('span',{staticClass:"translation"},[_vm._v("Pedir la cuenta")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("a)")]),_vm._v(" Could you bring me the bill, please?")]),_c('p',[_vm._v("¿Podría traerme la cuenta, por favor?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" How much is it/does it cost?")]),_c('p',[_vm._v("¿Cuánto es/cuesta?")])]),_c('div',{staticClass:"question"},[_c('p',[_c('b',[_vm._v("b)")]),_vm._v(" Could I pay by card/cash?")]),_c('p',[_vm._v("¿Podría pagar con tarjeta/en efectivo?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/files/blog/spanish-for-travellers/restaurant.pdf","target":"_blank"}},[_vm._v("Exercise to practise on how to ask in a restaurant")])])])
}]

export { render, staticRenderFns }